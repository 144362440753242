<template>
  <div class="file-uploader-container">
    <span class="label">{{label}}</span>
    <div class="file-uploader">
      <div class="overlay" v-if="uploading">
        <span v-if="uploading">
          Uploading {{type}}
          <div class="spinner-border spinner-border-sm mx-2" role="status"></div>
        </span>
      </div>

      <label class="uploader" style="height: 100%;" :style="
        'background-position:center;background-repeat: no-repeat;background-size: cover;background-image:url(' +
        assetUrl +
        ')'
      ">
        <video v-if="type == 'video' && !uploading && assetUrl" class="background-video" :src="assetUrl" autoplay onloadstart="this.volume=0;this.currentTime = 12;" loop="true"></video>
        <span v-if="!uploading && !assetUrl" class="description">Click to UPLOAD</span>
        <input type="file" class="hidden" ref="assetInput" @change="uploadAsset()" />
        <div class="actions">
          <span v-if="assetUrl" class="action replace">REPLACE</span>
          <span v-if="assetUrl" class="action delete" @click.prevent="deleteAsset()">DELETE</span>
        </div>
      </label>
    </div>
  </div>
</template>

<script>
import axios from "./../../../http.js";

export default {
  props: ["value", "label", "type", "folder"],
  data() {
    return {
      uploading: false,
      assetUrl: "",
    };
  },
  watch: {
    value(val) {
      this.assetUrl = val;
    },
  },
  mounted() {
    this.assetUrl = this.value;
  },
  methods: {
    deleteAsset() {
      this.assetUrl = "";
      this.$refs.assetInput.value = "";
      this.$emit("input", "");
    },
    uploadAsset() {
      var formData = new FormData();
      this.uploading = true;
      formData.append("asset", this.$refs.assetInput.files[0]);
      formData.append("type", this.type ?? "image");
      formData.append("folder", this.folder ?? "maestro");
      axios
        .post("/api/admin/assets", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(
          (response) => {
            this.assetUrl = response.data.url;
            this.$emit("input", response.data.url);
          },
          (err) => {
            console.log(err);
            alert(err.response.data.error);
          }
        )
        .finally(() => {
          this.uploading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.file-uploader-container {
  height: auto;
  .label {
    font-weight: 600;
  }
  .file-uploader {
    height: 170px;

    .uploader {
      cursor: pointer;
      background: lightgrey;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
    }

    position: relative;
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      z-index: 1;
      backdrop-filter: blur(5px);
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
    }
    .description {
      // make it readable
      color: white;
    }
    .actions {
      opacity: 0;
      transition: all 0.4s ease-in-out;
      &:hover {
        opacity: 1;
      }
      .action {
        cursor: pointer;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 50%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        padding: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;

        &.replace {
          left: 0;
        }
        &.delete {
          right: 0;
        }
        transition: all 0.4s ease-in-out;
        &:hover {
          background: rgba(0, 0, 0, 0.8);
        }
      }
    }

    .background-video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.hidden {
  visibility: hidden;
  display: none;
}
</style>