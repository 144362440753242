<template>
  <div class="blog-editor">
    <div v-if="Object.keys(errors).length !== 0" class="errors">
      <div v-for="fields,key in errors" :key="key">
        <div>
          <span style="text-transform:capitalize">{{ key }}:</span>
          <ul v-for="error,k in fields" :key="k">
            <li>{{ error }}</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row flex-grow-1">
      <div class="col flex-grow-1 d-flex flex-column">
        <div class="row">
          <div class="col">
            <div class="form-floating">
              <input type="text" name="title" v-model="article.title" class="form-control" :disabled="lock" placeholder="Article Title" />
              <label for="title">Title</label>
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col">
            <div class="form-floating">
              <input type="text" name="slug" v-model="article.slug" class="form-control" :disabled="lock || slugLocked" placeholder="Article Slug" />
              <label for="slug">
                Slug
                <a href="#" @click="slugLocked = false" v-if="slugLocked">change</a>
              </label>
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col">
            <div class="form-floating">
              <input type="text" name="excerpt" v-model="article.excerpt" class="form-control" :disabled="lock" placeholder="Article Excerpt" />
              <label for="excerpt">Excerpt</label>
            </div>
          </div>
        </div>

        <div class="mt-2 flex-grow-1">
          <editor :api-key="tinyMCEApiKey" :init="tinyMCEConfig" v-model="article.content" :value="article.content" v-if="!lock" />
          <div v-else class="d-flex justify-content-center align-items-center" style="height: 100%;width:100%;">
            <div>
              Loading editor
              <div class="spinner-border spinner-border-sm ms-2" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="row">
          <div class="col">
            <div class="row">
              <div class="col pe-0">
                <button class="vave-btn btn-green w-100" @click="saveArticle()" :disabled="lock">
                  <i class="far fa-save pe-1"></i>
                  {{ mode == 'create' ? 'Create' : 'Save' }}
                </button>
              </div>
              <div class="col">
                <button class="vave-btn btn-outline-blue w-100" @click="openPreview()" :disabled="lock || previewLocked">
                  <i class="fa fa-eye pe-1"></i> Preview
                </button>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col">
                <div class="form-floating">
                  <select class="form-select" v-model="article.status" name="status" :disabled="lock">
                    <option value="draft">Draft</option>
                    <option value="publish">Publish</option>
                  </select>
                  <label for="status">Status</label>
                </div>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col">
                <div class="form-floating">
                  <input type="datetime-local" name="published_at" class="form-control" v-model="article.published_at" :disabled="lock" />
                  <label for="published_at">Published At</label>
                </div>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col">
                <div class="checkboxes-container faux-input">
                  <label class="label">Categories</label>
                  <div v-for="cat,key in categories" :key="key" class="form-check">
                    <input :id="cat.name" v-model="selectedCategories[cat.id]" type="checkbox" class="form-check-input" :disabled="lock" />
                    <label :for="cat.name" class="form-check-label">{{cat.name}}</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col">
                <div class="faux-input">
                  <label for="cover" class="label">
                    Cover image
                    <small>(Click image to replace)</small>
                  </label>
                  <file-uploader v-model="article.media_url" label="Cover image" class="image-uploader" folder="blog" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WatchObject from "./WatchObject.vue";
import FileUploader from "./widgets/helpers/FileUploader";
import axios from "./../http.js";
import moment from "moment";
import _ from "lodash";
import Editor from "@tinymce/tinymce-vue";

export default {
  extends: WatchObject,
  components: { Editor, FileUploader },
  data() {
    return {
      categories: {},
      selectedCategories: {},
      article: {
        title: null,
        slug: null,
        status: "draft",
        published_at: new Date()
          .toISOString()
          .replaceAll("//", "-")
          .substring(0, 16),
        media_url: null,
        excerpt: null,
        content: null,
      },
      errors: {},
      mode: null,
      lock: false,
      slugLocked: true,
      previewLocked: false,
      unsavedChangesModal: null,
      tinyMCEApiKey: process.env.VUE_APP_TINYMCE_API_KEY,
      tinyMCEConfig: {
        height: "100%",
        menubar: false,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code",
        ],
        toolbar:
          "undo redo | formatselect | bold italic | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent image | removeformat | preview | productButton |",
        automatic_uploads: true,
        image_class_list: [{ title: "limit-width", value: "limit-width" }], // Note: if we add new classes, this one won't be added by default to all the images
        images_upload_url: process.env.VUE_APP_VAVEURL + "/api/admin/assets",
        images_upload_credentials: true,
        images_upload_handler: function (blobInfo, success, failure) {
          var formData = new FormData();
          formData.append("asset", blobInfo.blob());
          formData.append("type", "image");
          axios
            .post("/api/admin/assets", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(
              (response) => {
                console.log(response);
                success(response.data.url);
              },
              (error) => {
                console.log("error");
                console.log(error);
                failure("There was a network error.");
              }
            )
            .catch((error) => {
              console.log("catch");
              console.log(error);
              failure("There was an exception.");
            });
        },
        setup: function (editor) {
          var getDialogConfig = function (products, query) {
            let itemsList = [
              {
                type: "label",
                label: "Search for products",
                items: [
                  {
                    type: "grid",
                    columns: 2,
                    items: [
                      {
                        type: "input",
                        name: "search-input",
                        inputMode: "text",
                      },
                      {
                        type: "button",
                        name: "search-button",
                        text: "Search",
                      },
                    ],
                  },
                ],
              },
              {
                type: "label",
                label: "or",
                items: [
                  {
                    type: "button",
                    name: "load-all-products",
                    text: "Load All Products",
                  },
                ],
              },
            ];
            let label = "Products";
            if (query) {
              label = label + " matching the query: " + query;
            }
            if (products) {
              itemsList.push({
                type: "selectbox",
                name: "productsSelect",
                label: label,
                items: products.map((product) => {
                  return {
                    text: product.name,
                    value: String(product.remote_id),
                  };
                }),
                flex: true,
              });
            }
            return {
              title: "Insert Product",
              body: {
                type: "panel",
                items: itemsList,
              },
              onAction: function (api, details) {
                if (details.name == "load-all-products") {
                  api.block("Loading all products...");
                  axios.get("api/products?per_page=9999").then((r) => {
                    if (r.status != 200) {
                      console.error("problem fetching products");
                    }
                    api.redial(getDialogConfig(r.data.data));
                    api.unblock();
                  });
                }
                if (details.name == "search-button") {
                  let query = api.getData()["search-input"];
                  api.block("Loading...");
                  axios
                    .get("/api/products/search?q=" + query)
                    .then((response) => {
                      let products = response.data.data;
                      api.redial(getDialogConfig(products, query));
                      api.unblock();
                    });
                }
              },
              onSubmit: function (api) {
                editor.insertContent(
                  "[showproduct id=" + api.getData()["productsSelect"] + "]"
                );
                api.close();
              },
              buttons: [
                {
                  text: "Close",
                  type: "cancel",
                  onclick: "close",
                },
                {
                  text: "Insert",
                  type: "submit",
                  primary: true,
                  enabled: false,
                },
              ],
            };
          };

          editor.ui.registry.addButton("productButton", {
            icon: "format-painter",
            text: "Add Product",
            tooltip: "Insert product shortcode",
            disabled: false,
            onAction: function () {
              var _dialog = editor.windowManager.open(getDialogConfig());
              _dialog.redial(getDialogConfig());
            },
            onSetup: function (buttonApi) {
              var editorEventCallback = function (eventApi) {
                buttonApi.setDisabled(
                  eventApi.element.nodeName.toLowerCase() === "time"
                );
              };
              editor.on("NodeChange", editorEventCallback);

              /* onSetup should always return the unbind handlers */
              return function () {
                editor.off("NodeChange", editorEventCallback);
              };
            },
          });
        },
      },
    };
  },
  computed: {
    title() {
      return this.article.title;
    },
    computedCategories() {
      var chosen = [];
      _.forEach(this.selectedCategories, (selected, category) => {
        if (selected) {
          chosen.push(category);
        }
      });
      return chosen;
    },
  },
  mounted() {
    this.getCategories();
    this.mode = this.$route.params.id ? "edit" : "create";
    if (this.mode === "edit") {
      this.lock = true;
      this.getArticle();
    }
  },
  watch: {
    $route() {
      this.mode = this.$route.params.id ? "edit" : "create";
      this.getArticle();
    },
    title() {
      if (this.mode == "create") {
        this.article.slug = this.article.title
          .toLowerCase()
          .replace(/[^a-z0-9]/g, "-")
          .replace(/-+/g, "-")
          .replace(/^-+|-+$/g, "");
      }
    },
    article: {
      deep: true,
      handler() {
        this.notifyUnsavedChanges({
          article: this.article,
          categories: this.computedCategories,
        });
      },
    },
    computedCategories: {
      deep: true,
      handler() {
        this.notifyUnsavedChanges({
          article: this.article,
          categories: this.computedCategories,
        });
      },
    },
  },
  filters: {
    formattedDate(value) {
      return moment(String(value)).format("DD/MM/YYYY hh:mm");
    },
  },
  methods: {
    getCategories() {
      axios.get("/api/admin/posts/categories").then((response) => {
        if (response.data.data) {
          this.categories = response.data.data;
        }
      });
    },
    getArticle() {
      axios
        .get("/api/admin/posts/" + this.$route.params.id)
        .then((response) => {
          if (response.data.data) {
            setTimeout(() => {
              this.lock = false;
            }, 100);
            this.article = response.data.data;

            // Set the categories
            let tmpObject = {};
            response.data.data.categories.forEach((cat) => {
              tmpObject[cat.id] = true;
            });
            this.selectedCategories = tmpObject;

            // Fix published_at date format
            this.article.published_at = new Date(
              response.data.data.published_at
            )
              .toISOString()
              .replaceAll("//", "-")
              .substring(0, 16);

            // Generate the article hash so we can identify unsaved changes
            this.setObjectHash({
              article: this.article,
              categories: this.computedCategories,
            });
          }
        });
    },
    saveArticle() {
      this.errors = {};
      this.lock = true;
      axios
        .request({
          method: this.mode == "create" ? "post" : "put",
          url:
            "/api/admin/posts" +
            (this.mode == "create" ? "" : "/" + this.$route.params.id),
          data: this.article,
        })
        .then(
          (r) => {
            // Sync Categories
            axios
              .post(
                "/api/admin/posts/" + this.$route.params.id + "/categories",
                { categories: this.computedCategories }
              )
              .then((r) => {
                if (r.status != 200) {
                  this.$toast.error("Problem synching Categories");
                }
              })
              .finally(() => {
                setTimeout(() => {
                  this.lock = false;
                }, 500);
                if (r.status == 201) {
                  // it's a new article, let's redirect to the edit page
                  this.$toast.success("Post saved");
                  this.$router.push("/blog/" + r.data.data.id + "/edit");
                  return;
                }
                if (r.status == 200 || r.status == 204) {
                  this.$toast.success("Post saved");
                  return;
                }
                this.$toast.error("Error " + r.status);
              });
          },
          (e) => {
            this.lock = false;
            this.$toast.error("Validation Error");
            this.errors = e.response.data.errors;
            return;
          }
        )
        .finally(() => {
          this.slugLocked = true;
          this.setObjectHash({
            article: this.article,
            categories: this.computedCategories,
          });
        });
    },
    openPreview() {
      this.previewLocked = true;
      axios
        .post("api/previews", { type: "post", id: String(this.article.id) })
        .then(
          (response) => {
            if (response.status == 200 || response.status == 201) {
              window.open(
                process.env.VUE_APP_PWAURL +
                  "/blog/preview/" +
                  response.data.data.long_id,
                "_blank"
              );
              return;
            }
            this.$toast.error(
              "Could not open the preview: error " + response.status
            );
          },
          (e) => {
            this.$toast.error(
              "Could not open the preview: error " + e.response.status
            );
          }
        )
        .finally(() => {
          this.previewLocked = false;
        });
    },
  },
};
</script>

<style lang="scss">
.content-area {
  display: flex;
  flex-direction: column;
}
.blog-editor {
  padding: 10px;
  background: white;
  border-radius: 5px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  .errors {
    background: #dc3545;
    border-radius: 5px;
    color: white;
    padding: 10px;
    width: 80%;
  }
  .edit-button {
    text-decoration: underline;
    margin-left: 20px;
    color: gray;
  }

  .faux-input {
    padding: 10px;
    border: 1px solid #cdd4d9;
    .label {
      font-size: 0.8em;
      opacity: 0.65;
      padding-bottom: 10px;
    }
  }
  .image-uploader {
    width: 100%;
    height: 90%;
    min-height: 120px;
    border: none;
    label.uploader {
      border-radius: 5px;
    }
  }
  .save-button {
    width: 300px;
  }

  .form-floating {
    label {
      a {
        pointer-events: all;
      }
    }
  }
}
</style>